import React from "react";

export default function Footer(){
    return(
        
    <footer className="pb-30 pt-30">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="text-center">
                    <p className="fz-13">Portfolio of <span className="underline "><strong>Mohammad Saif Hossain</strong></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>

    )
}