import React from "react";
import './css/style.css'; // Import the CSS file
import portfolio1 from "./images/portfolio1.png";
import portfolio2 from "./images/portfolio2.png";
import portfolio3 from "./images/portfolio3.png";
import portfolio4 from "./images/portfolio4.png";
import portfolio_pdf from "./pdf/portfolio.pdf";
export default function Portfolio(){
    
    return(
<div className="sec-box  section-padding main-bg bord-thin-bottom " data-scroll-index="3" >
                <div className="sec-head mb-30">
                    <div className="row">
                        <div className="col-lg-8">
                            <h6 className="sub-title opacity-7 custom_mobile_margin mb-15">My Portfolio</h6>
                            <h3 > <span style={{color:'#EE5635'}}>Innovative Design,</span> Seamless Development: Your Website <span style={{color:'#EE5635'}}> Awaits</span> </h3>
                            {/* <p  >I have successfully crafted over 50 websites, showcasing a selection of my freelance projects. The remaining websites were conceived and developed during my tenure with a company, where I played a pivotal role in their design and development.</p> */}
                        </div>
                        <div className="col-lg-6 valign">
                            <div className="go-more full-width d-flex justify-content-end">
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gallery">
                    <div className="row">
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                                <div className="img-width"> 
                    
                                        <img className="img-width" src={portfolio1} alt="" />
                                    
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">TextCher</span>
                                        <h6 className="line-height-1">IT Agency Website
                                        </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".8s">
                                <div className="img-width">
                                    
                                        <img className="img-width" src={portfolio4} alt="" />
                                  
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">UI UX Design</span>
                                        <h6 className="line-height-1">Product Design
                                        </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".4s">
                                <div className="img-width">
                                    
                                        <img className="img-width" src={portfolio2} alt="" />
                                    
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">Dealzup</span>
                                        <h6 className="line-height-1">Landing Page
                                        </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".6s">
                                <div className="img-width">
                                    
                                        <img className="img-width" src={portfolio3} alt="" />
                                    
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">Manikya Jewellery</span>
                                        <h6 className="line-height-1">eCommerce Website</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div>
               
                <div className="butn-presv mt-4 custom_portfolio">
                  <a href={portfolio_pdf}  target="_blank"  rel="noreferrer" className="butn butn-md butn-bord radius-5 skew">
                    <span>View More</span>
                  </a>
                </div>
               </div>
               
            </div>
    )

}