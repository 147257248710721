import React,{useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { HashRouter as Router, Route, Routes } from 'react-router-dom';

// import { Link as ScrollLink, Element } from 'react-scroll';
import Navbar from "./components/Navbar";
//import Introduction from "./components/Introduction";
import Services from "./components/Services";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import './components/css/style.css'; // Import the CSS file
import './components/plugins/animate.css'; // Import the CSS file
import './components/plugins/justifiedGallery.css'; // Import the CSS file
import './components/plugins/magnific-popup.css'; // Import the CSS file
import './components/plugins/pe-icon-7-stroke.css'; // Import the CSS file
import './components/plugins/swiper.css'; // Import the CSS file
import './css/font-awesome.css'
import './css/font-awesome.min.css'
import './components/js/Scripts.js'
import MainNavbar from './components/MainNavbar';
import Portfolio from './components/Portfolio.js';
import Home from './components/Home.js';
// import logo from "./components/images/logo.png";
// import logo2 from "./components/images/2.png";

function App(){
  const [mode, setMode]  = useState('dark'); // whether dark mode is enabled or not.
  
  //we cannot use = sign in state mode.
  const toggleMode = ()=>{
    if(mode === 'light'){
      setMode('dark')
      document.body.style.backgroundColor="#232323";
      document.body.style.color="white";
      document.body.style.color="blue";
    //showAlert("Dark Mode has been enabled","success");

    }else{
      setMode('light')
      document.body.style.backgroundColor="#F1F1F1";
      document.body.style.color="black";
      document.querySelector("h6").style.color = "red";
      //showAlert("Light Mode has been enabled","success");
    }
  }
   return(
    <>
    <Router>
      <div className="container" >
        <Navbar mode={mode} toggleMode={toggleMode} />
        <MainNavbar  />
        <Routes>
          <Route path="/" element={<Home name="Intro"  mode={mode} toggleMode={toggleMode}/>} />

          <Route path="/Portfolio" element={<Portfolio name="Portfolio" mode={mode} toggleMode={toggleMode} />} />
         

          <Route path="/Services" element={<Services name="Services" mode={mode} toggleMode={toggleMode} />} />
          
          
         
          <Route path="/Skills" element={<Skills name="Skills"  mode={mode} toggleMode={toggleMode}/>} />
          
          <Route path="/Contact" element={<Contact name="Contact" mode={mode} toggleMode={toggleMode} />} />
        </Routes>
        <Footer />
      </div>
    </Router>  
    </>
  );
}

export default App;
