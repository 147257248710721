import React from "react";
import web from "./images/web.png";
import design from "./images/design.png";
import marketing from "./images/marketing.png";
import server from "./images/server.png";

export default function Services(props) {
  return (
    <div
      className="sec-box section-padding md-mb30 bord-thin-bottom"
      data-scroll-index="1"
    >
      <div className="sec-head mb-80 wow fadeInUp ">
        <div className="row md-mb30 justify-content-center">
          <div className="col-lg-6 text-center custom_mobile_margin">
            <h6 className="sub-title opacity-7 mb-15 ">My Expertise</h6>
            <h3 style={{color:'#DE576E'}}>
              Turn Information{" "}
              <span className="main-color">Into Actionable</span> Insights
            </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="item mb-40 wow fadeIn" data-wow-delay=".4s">
            <span className="icon-img-70 mb-30 opacity-7">
              <img src={web} alt="" />
            </span>
            <h6 className="text-u ls1 mb-15" >Web Development Mastery: Crafting Seamless Digital Experiences</h6>
            <p>
            Dedicated and passionate web designer and developer, proficient in transforming
             concepts into visually stunning and highly functional websites. Combining
              creativity with technical expertise, I stay at the forefront of design trends
               and emerging technologies such as React, Python, PHP, WordPress, Wix, Laravel, and custom solutions.
            </p>
            <div className="bord-color"></div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="item sm-mb40 wow fadeIn" data-wow-delay=".6s">
            <span className="icon-img-70 mb-30 opacity-7">
              <img src={marketing} alt="" />
            </span>
            <h6 className="text-u ls1 mb-15"> SEO and Social Media Marketing: Elevating Brand Visibility</h6>
            <p>
            A budding SEO and Marketing enthusiast, I bring passion to optimizing online presence and executing impactful campaigns. Eager to contribute dynamic strategies, I thrive in a professional environment that fosters continuous learning and growth. 
            {/* Whether it's enhancing search engine rankings, devising compelling content,
             or harnessing the power of social media. */}
            </p>
            <div className="bord-color"></div>
          </div>
        </div>
        

        <div className="col-md-6">
          <div className="item mb-40 wow fadeIn" data-wow-delay=".8s">
            <span className="icon-img-70 mb-30 opacity-7">
              <img src={server} alt="" />
            </span>
            <h6 className="text-u ls1 mb-15">Technology Solutions Architect: Ensuring Optimal Website Performance</h6>
            <p>
            An experienced professional specializing in Domain, SSL implementation, and web hosting solutions. With a proven track record, I ensure secure and reliable online environments, employing Continuous Integration (CI), Continuous Deployment (CD), and GitHub for optimal website performance.            </p>
            <div className="bord-color"></div>
          </div>
        </div>

        

        <div className="col-md-6">
          <div className="item sm-mb40 wow fadeIn" data-wow-delay=".2s">
            <span className="icon-img-70 mb-30 opacity-7">
              <img src={design} alt="" />
            </span>
            <h6 className="text-u ls1 mb-15">Design Innovation: Transforming Ideas into Stunning Realities</h6>
            <p>
            As a passionate designer, I specialize in creating captivating visuals that communicate effectively. From eye-catching banners and social media posts to dynamic videos, impactful images, and UI & UX design, I bring ideas to life with a keen eye for detail and creativity.
            </p>
            <div className="bord-color"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
