import React from "react";

export default function Skills(){
    
    
    return(
        
        <div className="sec-box skills section-padding bord-thin-bottom pb-30 pt-30 " style={{marginTop:"10px !important;"}} data-scroll-index="2">
                <div className="row">
                
                    <div className="col-lg-4 valign">
                        <div className="sec-head md-mb80 wow fadeIn custom_mobile_margin">
                        
                            {/* <h6 className="sub-title opacity-7 mb-15"></h6> */}
                            <h3><span  style={{color:'#F8C146 !important'}}>Skills</span> & Recognitions</h3>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="item mb-60 my-20  ">
                                    <div className="d-flex align-items-center mb-10 ">
                                        <div>
                                            <h6 className="fz-18">Website Design & Development </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30 " role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18">Graphic Design, UI & UX Design </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18"> Social Media Marketing  </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20 ">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18">Animation and Video Design </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18">HTML, CSS, JS, Bootstrap, React JS </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18"> Wordpress, WooCommerce , Wix,  CMS  </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18">PHP, Laravel </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20 ">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18">Python, Database & API </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item mb-60 my-20 ">
                                    <div className="d-flex align-items-center mb-10">
                                        <div>
                                            <h6 className="fz-18">SSL, Domain & Hosting </h6>
                                        </div>
                                    </div>
                                    <div class="progress mb-30" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar text-bg-warning" style={{width: '90%'}}>90%</div>
                                    </div>
                            
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
    )
}